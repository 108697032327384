
import { Options, Vue } from 'vue-class-component'
import message from 'ant-design-vue/lib/message'

@Options({
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  computed: {
    copyText() {
      return this.data
    },
  },
  methods: {
    copyToClipboard(text: string) {
      const currentWindow = window as any
      let isSuccess = false

      message.config({
        getContainer: () => this.$refs.copyRef,
        top: '-9px',
        duration: 2,
      })

      if (currentWindow.clipboardData && currentWindow.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return currentWindow.clipboardData.setData('Text', text)
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
      ) {
        const textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed'
        document.body.appendChild(textarea)
        textarea.select()

        try {
          isSuccess = true
          return document.execCommand('copy')
        } catch (ex) {
          isSuccess = false
          return false
        } finally {
          if (isSuccess) {
            message.success('Lien copié')
          } else {
            // alert('failed')
            message.error('Copie échouée')
          }
          document.body.removeChild(textarea)
        }
      }
    },
  },
})
export default class CopyToClipboard extends Vue {}
