<template>
  <div class="copy" ref="copyRef">
    <button class="copy-button" @click="copyToClipboard(copyText)">
      <svg
        t="1616663130976"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3162"
        width="24"
        height="24"
      >
        <path
          d="M682.666667 682.666667v170.666666c0 49.152-36.181333 85.333333-85.333334 85.333334H170.666667c-49.152 0-85.333333-36.181333-85.333334-85.333334V426.666667c0-49.152 36.181333-85.333333 85.333334-85.333334h170.666666V170.666667c0-49.152 36.181333-85.333333 85.333334-85.333334h426.666666c49.152 0 85.333333 36.181333 85.333334 85.333334v426.666666c0 49.152-36.181333 85.333333-85.333334 85.333334h-170.666666z m-85.333334 0h-170.666666c-49.152 0-85.333333-36.181333-85.333334-85.333334v-170.666666H170.666667v426.666666h426.666666v-170.666666zM426.666667 170.666667v426.666666h426.666666V170.666667H426.666667z"
          p-id="3163"
          fill="#4b45b2"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import message from 'ant-design-vue/lib/message'

@Options({
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  computed: {
    copyText() {
      return this.data
    },
  },
  methods: {
    copyToClipboard(text: string) {
      const currentWindow = window as any
      let isSuccess = false

      message.config({
        getContainer: () => this.$refs.copyRef,
        top: '-9px',
        duration: 2,
      })

      if (currentWindow.clipboardData && currentWindow.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return currentWindow.clipboardData.setData('Text', text)
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
      ) {
        const textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed'
        document.body.appendChild(textarea)
        textarea.select()

        try {
          isSuccess = true
          return document.execCommand('copy')
        } catch (ex) {
          isSuccess = false
          return false
        } finally {
          if (isSuccess) {
            message.success('Lien copié')
          } else {
            // alert('failed')
            message.error('Copie échouée')
          }
          document.body.removeChild(textarea)
        }
      }
    },
  },
})
export default class CopyToClipboard extends Vue {}
</script>

<style lang="scss" scoped>
.copy-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
</style>
<style lang="scss">
.copy-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.copy {
  .ant-message {
    position: absolute;
    white-space: nowrap;
    min-width: 123px;
    left: 100%;
  }
  .ant-message-custom-content {
    display: flex;
    align-items: center;
  }
  .ant-message-notice-content {
    padding: 0;
    border-radius: 10px;
    box-shadow: none;
  }
  .ant-message-custom-content {
    border-radius: 10px;
    padding: 3px 5px;
    color: #fff;
    .anticon {
      color: #fff;
    }
  }
  .ant-message-success {
    background-color: #52c41a;
  }
  .ant-message-error {
    background-color: #ff4d4f;
  }
}
</style>
