<template>
  <div class="body-content row">
    <div class="col-lg-3">
      <month-statistic :month="currentMonthHistory.date" class="mb-5" />
      <side-card-component
        :count="currentMonthHistory.visitor || 0"
        icon="screen"
      >
        visiteur(s) <br />
        généré(s)
      </side-card-component>
      <side-card-component
        :count="currentMonthHistory.subscribe_freemium || 0"
        icon="task"
      >
        souscription(s) <br />
        freemium
      </side-card-component>
      <side-card-component
        :count="currentMonthHistory.subscribe || 0"
        icon="task"
      >
        souscription(s) <br />
        payée(s)
      </side-card-component>
      <side-card-component
        :count="currentMonthHistory.gains || 0"
        icon="calculator"
      >
        # Gagné(s)
      </side-card-component>
    </div>
    <div class="col-lg-9">
      <card-component title="Vos outils de travail">
        <div class="row">
          <div class="col-md-8 url-form">
            <floating-label>
              <label>Votre lien d'affilié</label>
              <input
                type="text"
                class="form-control"
                :value="computedData.datas.affiliate_url"
                disabled
              />
              <copy-to-clipboard
                :data="computedData.datas.affiliate_url || ''"
                class="copy-url"
              />
            </floating-label>
          </div>
          <div class="col-md-4">
            <!-- <div class="side-block">
              <div class="img-space-shadow">
                <img :src="require(`@/assets/icons/document.svg`)" alt="" />
              </div>
              <div>
                Télécharger la plaquette <br />
                commerciale
              </div>
            </div> -->
          </div>
        </div>
      </card-component>
      <card-component title="Historique des gains">
        <table-component
          :columns="computedData.columns"
          :data="history.details"
          @change="handleChangeTable"
        >
          <template #date="{ column }">
            <i class="calendar-blue"></i>
            {{ column }}
          </template>
          <template #visitor="{ column }">
            {{ column }}
          </template>
          <template #subscribe_freemium="{ column }">
            {{ column }}
          </template>
          <template #subscribe="{ column }">
            {{ column }}
          </template>
          <template #gains="{ column }"> {{ column }} € </template>
          <template #footer>
            <table-component
              :columns="computedData.columns"
              :data="[currentYearHistory]"
              :header="false"
            >
              <template #date="{ column }">
                <i class="calendar"></i>
                <span class="td-footer-info">
                  {{ column }}
                </span>
              </template>
              <template #visitor="{ column }">
                <span class="td-footer-info">
                  {{ column }}
                </span>
              </template>
              <template #subscribe_freemium="{ column }">
                <span class="td-footer-info">
                  {{ column }}
                </span>
              </template>
              <template #subscribe="{ column }">
                <span class="td-footer-info">
                  {{ column }}
                </span>
              </template>
              <template #gains="{ column }">
                <span class="td-footer-info"> {{ column }} € </span>
              </template>
            </table-component>
          </template>
        </table-component>
      </card-component>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import SideCardComponent from '@/components/DataDisplay/SideCard/SideCard.vue'
import FloatingLabel from '@/components/DataEntry/FloatingLabel/FloatingLabel.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import MonthStatistic from '@/components/DataDisplay/MonthStatistic/MonthStatistic.vue'
import CopyToClipboard from '@/components/General/CopyToClipboard/CopyToClipboard.vue'

@Options({
  name: 'DilypseAffiliation',
  components: {
    SideCardComponent,
    CardComponent,
    FloatingLabel,
    TableComponent,
    MonthStatistic,
    CopyToClipboard,
  },
  computed: {
    ...mapGetters(['getdashboardAffiliation']),
    computedData() {
      return this.getdashboardAffiliation || { datas: { history: {} } }
    },
    history() {
      return this.computedData.datas.history || {}
    },
    currentYearHistory() {
      return this.history.currentYear || {}
    },
    currentMonthHistory() {
      return this.history.currentMonth || {}
    },
  },
  mounted() {
    this.setDataDashboardAffiliation()
  },
  methods: {
    ...mapActions(['setDataDashboardAffiliation']),
    handleChangeTable(sorter: any) {
      console.log(sorter)
    },
  },
})
export default class DilypseAffiliation extends Vue {}
</script>

<style lang="scss">
.url-form .copy-url {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
</style>
