
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import SideCardComponent from '@/components/DataDisplay/SideCard/SideCard.vue'
import FloatingLabel from '@/components/DataEntry/FloatingLabel/FloatingLabel.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import MonthStatistic from '@/components/DataDisplay/MonthStatistic/MonthStatistic.vue'
import CopyToClipboard from '@/components/General/CopyToClipboard/CopyToClipboard.vue'

@Options({
  name: 'DilypseAffiliation',
  components: {
    SideCardComponent,
    CardComponent,
    FloatingLabel,
    TableComponent,
    MonthStatistic,
    CopyToClipboard,
  },
  computed: {
    ...mapGetters(['getdashboardAffiliation']),
    computedData() {
      return this.getdashboardAffiliation || { datas: { history: {} } }
    },
    history() {
      return this.computedData.datas.history || {}
    },
    currentYearHistory() {
      return this.history.currentYear || {}
    },
    currentMonthHistory() {
      return this.history.currentMonth || {}
    },
  },
  mounted() {
    this.setDataDashboardAffiliation()
  },
  methods: {
    ...mapActions(['setDataDashboardAffiliation']),
    handleChangeTable(sorter: any) {
      console.log(sorter)
    },
  },
})
export default class DilypseAffiliation extends Vue {}
